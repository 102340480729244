@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}




@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';



  /* Custom Scrollbar */
  .custom-scrollbar::-webkit-scrollbar {
    height: 5px; /* Set the height of the horizontal scrollbar */
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #4e4c4c; /* Light background for the track */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #bdbdbd; /* Gray color for the thumb */
    border-radius: 4px; /* Rounded corners */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #a0a0a0; /* Darker shade when hovered */
  }



  