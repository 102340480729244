/*====================================
#1.3 wpo-blog-area
=====================================*/

.blog-area.section-padding {
    padding-bottom: 100px;
}

.blog-area-2.section-padding {
    padding-bottom: 90px;
}

.blog-area {
    background: #f9f9fc;
}

.blog-item {
    margin-bottom: 30px;
}

.blog-content {
    padding: 20px 30px 30px;
    background: #fff;
    box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.04);
}

.blog-content h3 {
    font-size: 22px;
    line-height: 35px;
    margin-bottom: 20px;
    font-weight: 700;
    font-family: 'Nunito Sans', sans-serif;
}

.blog-content h3 a {
    color: #000;
    transition: all .3s;
}

.blog-content h3 a:hover {
    color: #00b3d6;
}

.blog-content ul li {
    display: inline-block;
    padding: 0 10px;
}

.blog-content ul li a {
    color: #000;
    transition: all .3s;
}

.blog-content ul li {
    color: #666666;
}

.blog-content ul li a:hover {
    color: #666666;
}

.blog-img img {
    -webkit-filter: grayscale(0);
    -moz-filter: grayscale(0);
    -o-filter: grayscale(0);
    -ms-filter: grayscale(0);
    filter: grayscale(0);
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    width: 100%;
}

.blog-img img:hover {
    -webkit-filter: grayscale(50%);
    -moz-filter: grayscale(50%);
    -o-filter: grayscale(50%);
    -ms-filter: grayscale(50%);
    filter: grayscale(50%);
}

.blog-page-area .blog-item .post-meta {
    padding-top: 20px;
}

.blog-page-area .blog-item .post-meta li {
    color: #666;
    font-size: 16px;
    display: inline-block;
    position: relative;
    padding: 0 15px;
}


.blog-item .post-meta>li:first-child {
    padding: 0;
}

.blog-item .post-meta>li a {
    color: #666;
}

.blog-item .post-meta>li a:hover {
    color: #00b3d6;
}

.blog-item .post-meta li {
    position: relative;
}

.blog-item .post-meta>li+li+li {
    padding-left: 0;
}

.blog-item .post-meta li img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-top: -2px;
}

@media(max-width: 1200px) {
    .blog-item .post-meta li {
        font-size: 14px;
    }

    .blog-content {
        padding: 15px;
    }
}

@media(max-width: 991px) {
    .blog-content ul li {
        padding: 0 5px;
    }

    .blog-area.section-padding {
        padding-top: 94px;
        padding-bottom: 65px;
    }
}

@media(max-width: 767px) {
    .custom-grid {
        width: 50%;
        float: left;
    }

    .blog-content h3 {
        font-size: 18px;
        line-height: 26px;
    }

    .blog-content {
        padding: 20px 15px 20px;
    }

    .section-padding {
        padding-top: 60px;
        padding-bottom: 65px;
    }
}

@media(max-width: 630px) {
    .custom-grid {
        width: 100%;
        float: none;
    }

    .blog-area.section-padding {
        padding-top: 54px;
        padding-bottom: 45px;
    }

   
}