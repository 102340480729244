/*------------------------------------
wpo-blog sidebar
------------------------------------*/
.wpo-blog-sidebar {
  /*** search-widget ***/
  /*** category-widget ***/
  /*** recent-post-widget ***/
  /*** quote-widget ****/
  /*** tag-widget ***/
}

@media screen and (min-width: 992px) {
  .wpo-blog-sidebar {
      padding-left: 0px;
  }
}

@media (max-width: 991px) {
  .wpo-blog-sidebar {
      margin-top: 80px;
  }
}

@media (max-width: 991px) {
  .wpo-blog-sidebar-2 {
      margin-top: 0;
      margin-bottom: 80px;
  }
}

@media (max-width: 767px) {
  .wpo-blog-sidebar {
      margin-top: 60px;
  }
}

@media (max-width: 767px) {
  .wpo-blog-sidebar-2 {
      margin-top: 0;
      margin-bottom: 80px;
  }
}


.wpo-blog-sidebar .widget h3 {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  position: relative;
  text-transform: capitalize;
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #ebebeb;
}

.wpo-blog-sidebar .widget h3:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 60px;
  height: 4px;
  background: #00c6d1;
  background: -webkit-linear-gradient(left, #00a7d5, #00ded8);
  background: -moz-linear-gradient(left, #00a7d5, #00ded8);
  background: -o-linear-gradient(left, #00a7d5, #00ded8);
  background: -ms-linear-gradient(left, #00a7d5, #00ded8);
  background: -webkit-gradient(linear, left top, right top, from(#00a7d5), to(#00ded8));
  background: linear-gradient(left, #00a7d5, #00ded8);
}

.wpo-blog-sidebar>.widget+.widget {
  margin-top: 65px;
}

.wpo-blog-sidebar .search-widget {
  border: 0;
  padding: 0;
}

.wpo-blog-sidebar .search-widget form div {
  position: relative;
}

.wpo-blog-sidebar .search-widget input {
  height: 50px;
  font-size: 16px;
  font-size: 1rem;
  border: 1px solid #ebebeb;
  padding: 6px 50px 6px 20px;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}

.wpo-blog-sidebar .search-widget input:focus {
  background-color: rgba(0, 198, 209, 0.1);
}

.wpo-blog-sidebar .search-widget form button {
  background: #00c6d1;
  background: -webkit-linear-gradient(left, #00a7d5, #00ded8);
  background: -moz-linear-gradient(left, #00a7d5, #00ded8);
  background: -o-linear-gradient(left, #00a7d5, #00ded8);
  background: -ms-linear-gradient(left, #00a7d5, #00ded8);
  background: -webkit-gradient(linear, left top, right top, from(#00a7d5), to(#00ded8));
  background: linear-gradient(left, #00a7d5, #00ded8);
  font-size: 20px;
  font-size: 1.25rem;
  color: #fff;
  border: 0;
  outline: 0;
  width: 50px;
  height: 50px;
  line-height: 54px;
  position: absolute;
  right: 0;
  top: 0;
}

.wpo-blog-sidebar .category-widget ul {
  list-style: none;
}

.wpo-blog-sidebar .category-widget ul li {
  font-size: 15px;
  font-size: 0.9375rem;
  position: relative;
  padding-left: 20px;
  padding-bottom: 10px;
}

.wpo-blog-sidebar .category-widget ul li:before {
  content: "";
  background: #bbb;
  width: 8px;
  height: 8px;
  position: absolute;
  left: 0;
  top: 4px;
  border-radius: 50%;
}

@media (max-width: 767px) {
  .wpo-blog-sidebar .category-widget ul li {
      font-size: 14px;
      font-size: 0.875rem;
  }
}

.wpo-blog-sidebar .category-widget ul li span {
  position: absolute;
  right: 0;
  color: #03a2cb;
}

.wpo-blog-sidebar .category-widget ul>li+li {
  margin-top: 12px;
}

.wpo-blog-sidebar .category-widget ul a {
  display: block;
  color: #777;
}

.wpo-blog-sidebar .category-widget ul a:hover {
  color: #029ca5;
}

.wpo-blog-sidebar .category-widget ul li:hover:before {
  background: #029ca5;
}

.wpo-blog-sidebar .recent-post-widget .post {
  overflow: hidden;
}

.wpo-blog-sidebar .recent-post-widget .posts>.post+.post {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #f2f0f0;
}

.wpo-blog-sidebar .recent-post-widget .post .img-holder {
  width: 68px;
  float: left;
}

.wpo-blog-sidebar .recent-post-widget .post .details {
  width: calc(100% - 68px);
  float: left;
  padding-left: 20px;
}

.wpo-blog-sidebar .recent-post-widget .post h4 {
  font-size: 16px;
  line-height: 1.3em;
  margin: 0 0 0.3em;
  color: #444444;
}

@media (max-width: 1199px) {
  .wpo-blog-sidebar .recent-post-widget .post h4 {
      margin: 0;
  }
}

.wpo-blog-sidebar .recent-post-widget .post h4 a {
  display: inline-block;
  color: #242f6c;
  font-family: 'Now';
  font-weight: 400;
}

@media (max-width: 1199px) {
  .wpo-blog-sidebar .recent-post-widget .post h4 {
      font-size: 14px;
  }
}

.wpo-blog-sidebar .recent-post-widget .post h4 a:hover {
  color: #029ca5;
}

.wpo-blog-sidebar .recent-post-widget .post .details .date {
  font-size: 14px;
  color: #888;
}

.wpo-blog-sidebar .recent-post-widget .post .details .date i {
  display: inline-block;
  padding-right: 7px;
}

.wpo-blog-sidebar .quote-widget {
  border: 0;
  padding: 0;
}

.wpo-blog-sidebar .quote-widget .quote {
  background-color: #f5f5f5;
  padding: 30px;
}

.wpo-blog-sidebar .quote-widget .quote p {
  margin: 0;
}

.wpo-blog-sidebar .quote-widget i {
  display: block;
}

.wpo-blog-sidebar .quote-widget .fi:before {
  font-size: 35px;
}

.wpo-blog-sidebar .quote-widget .quoter {
  position: relative;
  padding: 25px 25px 25px 110px;
}

.wpo-blog-sidebar .quote-widget .quoter .img-holder {
  position: absolute;
  left: 0;
}

.wpo-blog-sidebar .quote-widget .quoter img {
  padding: 5px;
  border: 1px solid #dcdcdc;
  border-radius: 50%;
}

.wpo-blog-sidebar .quote-widget .quoter h4 {
  font-size: 16px;
  font-size: 1rem;
  font-weight: bold;
  margin: 0 0 0.3em;
  padding-top: 1.3em;
}

.wpo-blog-sidebar .quote-widget .quoter h4+p {
  font-size: 14px;
  font-size: 0.875rem;
  margin: 0;
}

.wpo-blog-sidebar .tag-widget ul {
  overflow: hidden;
  list-style: none;
}

.wpo-blog-sidebar .tag-widget ul li {
  float: left;
  margin: 0 8px 8px 0;
}

.wpo-blog-sidebar .tag-widget ul li a {
  font-size: 14px;
  display: inline-block;
  padding: 10px 22px;
  color: #525252;
  border: 1px solid #ebebeb;
  border-radius: 0;
}

@media(max-width: 1200px) {
  .wpo-blog-sidebar .tag-widget ul li a {
      padding: 10px 13px;
      font-size: 14px;
  }
}

.wpo-blog-sidebar .tag-widget ul li a:hover {
  background: #029ca5;
  color: #fff;
}

.instagram ul {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  margin: 0px -3px;
}

.instagram ul li {
  -ms-flex: 0 0 33.33%;
  flex: 0 0 33.33%;
  max-width: 33.33%;
  margin-bottom: 5px;
  padding: 0px 3px;
}

.instagram ul li img {
  width: 100%;
}

/**** pagination ****/
.pagination-wrapper {
  text-align: center;
  margin-top: 45px;
}

@media (max-width: 991px) {
  .pagination-wrapper {
      text-align: left;
      margin-top: 40px;
  }
}

.pagination-wrapper .pg-pagination {
  display: inline-block;
  overflow: hidden;
  list-style-type: none;
  text-align: center;
  margin-bottom: 0;
}

.pagination-wrapper .pg-pagination li {
  float: left;
  margin-right: 10px;
}

@media (max-width: 767px) {
  .pagination-wrapper .pg-pagination li {
      margin-right: 5px;
  }
}

.pagination-wrapper .pg-pagination li:last-child {
  margin-right: 0;
}

.pagination-wrapper .pg-pagination li a {
  background-color: #f5f5f5;
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 600;
  color: #666;
  border: 0;
  display: block;
  border-radius: 5px;
}

.pagination-wrapper .pg-pagination .active a,
.pagination-wrapper .pg-pagination li a:hover {
  background: #00c6d1;
  background: -webkit-linear-gradient(left, #00a7d5, #00ded8);
  background: -moz-linear-gradient(left, #00a7d5, #00ded8);
  background: -o-linear-gradient(left, #00a7d5, #00ded8);
  background: -ms-linear-gradient(left, #00a7d5, #00ded8);
  background: -webkit-gradient(linear, left top, right top, from(#00a7d5), to(#00ded8));
  background: linear-gradient(left, #00a7d5, #00ded8);
  color: #fff;
}

.pagination-wrapper .pg-pagination .fi:before {
  font-size: 15px;
  font-size: 0.9375rem;
}

.pagination-wrapper-left {
  text-align: left;
}

.pagination-wrapper-right {
  text-align: right;
}

@media screen and (min-width: 1200px) {
  .pagination-wrapper-right {
      padding-right: 50px;
  }
}

@media (max-width: 991px) {
  .pagination-wrapper-right {
      margin-top: 45px;
      text-align: left;
  }
}

/*------------------------------------
category sidebar
------------------------------------*/
.category-sidebar {
  /*** category-widget ***/
  /*** tag-widget ***/
}

@media screen and (min-width: 992px) {
  .category-sidebar {
      padding-right: 45px;
  }
}

@media (max-width: 991px) {
  .category-sidebar {
      margin-bottom: 80px;
      max-width: 400px;
  }
}

@media (max-width: 767px) {
  .category-sidebar {
      margin-bottom: 60px;
      max-width: 400px;
  }
}

.category-sidebar .widget {
  padding: 30px;
  -webkit-box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);
  box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);
}

.category-sidebar .widget h3 {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 600;
  border-bottom: 1px solid #ebebeb;
  margin: 0 -30px 30px;
  padding: 0 0 30px 30px;
  position: relative;
  text-transform: capitalize;
}

.category-sidebar>.widget+.widget {
  margin-top: 30px;
}

.category-sidebar .category-widget ul {
  list-style: none;
}

.category-sidebar .category-widget ul li {
  font-size: 15px;
  font-size: 0.9375rem;
  position: relative;
  padding-left: 20px;
}

.category-sidebar .category-widget ul li:before {
  content: "";
  background: #bbb;
  width: 6px;
  height: 6px;
  position: absolute;
  left: 0;
  top: 8px;
}

@media (max-width: 767px) {
  .category-sidebar .category-widget ul li {
      font-size: 14px;
      font-size: 0.875rem;
  }
}

.category-sidebar .category-widget ul li span {
  position: absolute;
  right: 0;
}

.category-sidebar .category-widget ul>li+li {
  margin-top: 12px;
}

.category-sidebar .category-widget ul a {
  display: block;
  color: #525252;
}

.category-sidebar .category-widget ul a:hover {
  color: #029ca5;
}

.category-sidebar .category-widget ul li:hover:before {
  background: #029ca5;
}

.category-sidebar .tag-widget {
  padding: 15px 0;
}

.category-sidebar .tag-widget ul {
  list-style: none;
}

.category-sidebar .tag-widget ul li {
  font-size: 15px;
  font-size: 0.9375rem;
  position: relative;
}

.category-sidebar .tag-widget ul li:before {
  content: "";
  width: 15px;
  height: 15px;
  border: 3px solid #d7d7d7;
  border-radius: 50%;
  position: absolute;
  left: 30px;
  top: 15px;
}

@media (max-width: 767px) {
  .category-sidebar .tag-widget ul li {
      font-size: 14px;
      font-size: 0.875rem;
  }
}

.category-sidebar .tag-widget ul a {
  display: block;
  color: #525252;
  padding: 12px 15px 12px 60px;
}

.category-sidebar .tag-widget ul a:hover {
  background: #029ca5;
  background: -webkit-linear-gradient(left, #029ca5, #3a9ce0);
  background: -moz-linear-gradient(left, #029ca5, #3a9ce0);
  background: -o-linear-gradient(left, #029ca5, #3a9ce0);
  background: -ms-linear-gradient(left, #029ca5, #3a9ce0);
  background: -webkit-gradient(linear, left top, right top, from(#029ca5), to(#3a9ce0));
  background: linear-gradient(left, #029ca5, #3a9ce0);
  color: #fff;
}

.category-sidebar .tag-widget ul li:hover:before {
  border-color: #fff;
}


.profile-img {
  padding: 40px;
}

.profile-img img {
  border-radius: 50%;
}

.profile-img h2 {
  font-size: 25px;
  font-weight: 700;
  margin-top: 20px;
}
.profile-img p{
  margin-bottom: 0;
}

.profile-widget {
  text-align: center;
  background: #f3fbfd;
  border: 1px solid #ebebeb;
}

.pro-social ul {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  background: #c8f2fd;
  margin-bottom: 0;
}

.pro-social ul li {
  margin: 0 10px;
}

.pro-social ul li a {
  color: #283a5e;
}

.wpo-blog-sidebar {
  padding-left: 30px;
}

.left .wpo-blog-sidebar {
  padding-left: 0;
  padding-right: 30px;
}


@media(max-width: 1200px) {
  .profile-img h2 {
      font-size: 20px;  
  }
  .profile-img {
      padding: 30px;
  }
}

@media(max-width: 767px) {
  .post-bloquote p {
      font-size: 15px !important;
  }

  .post.post-text-wrap {
      padding: 20px;
  }

}

@media(max-width: 991px) {
  .wpo-blog-sidebar {
      padding-left: 0px;
  }

  .left .wpo-blog-sidebar {
      padding-left: 0;
      padding-right: 0px;
      margin-top: 30px;
      margin-bottom: 0;
  }

}
