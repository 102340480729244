/*=====================================
#1.2 support-area
======================================*/

.support-text span {
    background: -webkit-linear-gradient(to left, #00b3d6, #00ded8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}

.support-text h2 {
    font-size: 37px;
    margin-bottom: 25px;
}

.support-text p {
    margin-bottom: 30px;
    font-size: 18px;
}

.progress-area {
    margin-top: 25px;
}

.progress-area span {
    color: #242f6c;
    font-size: 25px;
    display: block;
    text-align: center;
    font-weight: 700;
}

.progress {
    width: 150px;
    height: 150px!important;
    line-height: 150px;
    background: none;
    margin: 0 auto;
    box-shadow: none;
    position: relative;
    overflow: unset;
    border-radius: 50%!important;
    margin-bottom: 20px;
}

.progress:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 20px solid #ebebeb;
    position: absolute;
    top: 0;
    left: 0;
}

.progress>span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
}

.progress .progress-left {
    left: 0;
}

.progress .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 20px;
    border-style: solid;
    position: absolute;
    top: 0;
}

.progress .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
}

.progress .progress-right {
    right: 0;
}

.progress .progress-right .progress-bar {
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
    animation: loading-1 1.8s linear forwards;
}

.progress .progress-value {
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: #fff;
    font-size: 24px;
    color: #333;
    line-height: 135px;
    text-align: center;
    position: absolute;
    top: 5%;
    left: 5%;
    font-weight: 700;
}

.progress.blue .progress-left .progress-bar {
    animation: loading-2 1.5s linear forwards 1.8s;
}

.progress.s1 .progress-bar {
    border-color: #0dc2f8;
}
.progress.s1:after{
    border-color: rgba(182,228,254, .2);
}

.progress.s1 .progress-left .progress-bar {
    animation: loading-3 1s linear forwards 1.8s;
}

.progress.s2 .progress-bar {
    border-color: #ff8f84;
}
.progress.s2:after {
    border-color: #ffdadc;
}

.progress.s2 .progress-left .progress-bar {
    animation: loading-4 0.4s linear forwards 1.8s;
}

.progress.s3 .progress-bar {
    border-color: #8379fe;
}

.progress.s3:after{
    border-color: #d6d3ff;
}

.progress.s3 .progress-left .progress-bar {
    animation: loading-5 1.2s linear forwards 1.8s;
}

@keyframes loading-1 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

@keyframes loading-2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}

@keyframes loading-3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(120deg);
        transform: rotate(120deg);
    }
}

@keyframes loading-4 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(46deg);
        transform: rotate(46deg);
    }
}

@keyframes loading-5 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(36deg);
        transform: rotate(36deg);
    }
}



.progress:before {
    position: absolute;
    left: -14px;
    top: -15px;
    width: 120%;
    height: 120%;
    background: #fff;
    content: "";
    border-radius: 50%;
}

@media(max-width: 1200px) {
    .progress-area span {
        font-size: 20px;
    }
}

@media(max-width: 991px) {
    .progress-area {
        margin-top: 40px;
    }

    .progress-wrap {
        text-align: center;
    }

    .support-area.section-padding{
        padding: 40px 0 70px;
    }
}

@media(max-width: 767px) {
    .progress-area .custom-grid {
        width: 33.33%;
        float: left;
    }

}

@media(max-width: 575px) {
    .progress-area .custom-grid {
        width: 50%;
        float: left;
        margin-bottom: 30px;
    }

    .progress-area .custom-grid:last-child{
        margin-bottom: 0;
    }

    .progressbar3 {
        margin-top: 30px;
    }

    .support-text h2 {
        font-size: 25px;
    }

}

@media(max-width: 400px) {
    .progress-area .custom-grid {
        width: 100%;
        float: none;
    }

    .progress-wrap {
        text-align: center;
    }

    .progressbar2 {
        margin-top: 30px;
    }
}