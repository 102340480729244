/* Custom Scrollbar */
.custom-scrollbar {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #bdbdbd #f0f0f0; /* Thumb and track colors for Firefox */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 2px; /* Set the width of the vertical scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f0f0f0; /* Light background for the track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #bdbdbd; /* Gray color for the thumb */
  border-radius: 3px; /* Rounded corners */

}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #a0a0a0; /* Darker shade when hovered */
}
