/*=========================================
#8.0 service-area
==========================================*/
.service-area.section-padding {
    background: url(../../images/service/bg.png) no-repeat center center / cover;
    padding: 200px 0;
}

.service-title span {
    font-size: 18px;
    padding: 8px 25px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 9px 1px rgba(51, 100, 219, 0.1);
    display: inline-block;
}

.service-title h2 {
    font-size: 40px;
    line-height: 65px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.routine-table ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin-bottom: 2px;
    background: #fff;
    box-shadow: 0px 0px 9px 1px rgba(51, 100, 219, 0.1);
    border-radius: 5px;
}

.routine-table ul li {
    padding: 20px;
    flex-basis: 33.33%;
}

.routine-table .routne-head {
    background: -moz-linear-gradient(173deg, rgb(255, 173, 111, .8) 0%, rgb(255, 125, 144) 100%);
    background: -webkit-linear-gradient(173deg, rgb(255, 173, 111, .8) 0%, rgb(255, 125, 144) 100%);
    background: -ms-linear-gradient(173deg, rgb(255, 173, 111, .8) 0%, rgb(255, 125, 144) 100%);
    background: linear-gradient(173deg, rgb(255, 173, 111, .8) 0%, rgb(255, 125, 144) 100%);
}

.routine-table {
    margin-top: 30px;
    margin-bottom: 20px;
}

.routine-table .routne-head li {
    color: #fff;
}

.service-area .row {
    display: flex;
    align-items: center;
}

.service-img {
    width: 145%;
    position: relative;
    right: -30px;
}

@media(max-width: 991px) {
    .service-area .row {
        display: unset;
    }

    .service-area.section-padding{
        background: #f1fcfb;
        padding: 100px 0;
    }

    .service-wrap-s2 .service-item {
        margin-top: 30px;
    }

    .service-img {
        width: 100%;
        position: relative;
        right: 0;
    }

}

.service-wrap-s2 {
    padding-top: 120px;
}

.service-wrap-s2 .service-img {
    right: auto;
    left: -200px;
    width: 130%;
}


@media(max-width: 1200px) {
    .service-wrap-s2 .service-img {
        left: -80px;
    }
}

@media(max-width: 991px) {
    .service-wrap-s2 {
        padding-top: 0;
    }
}

/*.===============================
#0.9 wpo-counter-area
===============================*/

.wpo-counter-grids {
    margin-top: 40px;
}

.wpo-counter-grids .grid {
    width: 31%;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    text-align: center;
    position: relative;
}

.counter-number {
    width: 150px;
    height: 150px;
    line-height: 150px;
    background: #ddd;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    margin: auto;
}

.counter-number:before {
    position: absolute;
    left: 25px;
    top: 25px;
    width: 100px;
    height: 100px;
    background: #fff;
    content: "";
    border-radius: 50%;
    z-index: -1;
}

.wpo-counter-grids .grid:nth-child(1) .counter-number {
    background: #00c6d1;
    background: -webkit-linear-gradient(to left, #00a7d5, #00ded8);
    background: -moz-linear-gradient(to left, #00a7d5, #00ded8);
    background: -o-linear-gradient(to left, #00a7d5, #00ded8);
    background: -ms-linear-gradient(to left, #00a7d5, #00ded8);
    background: -webkit-gradient(linear, left top, right top, from(#00a7d5), to(#00ded8));
    background: linear-gradient(to left, #00a7d5, #00ded8);
}

.wpo-counter-grids .grid:nth-child(2) .counter-number {
    background: -moz-linear-gradient(173deg, rgb(255, 173, 111) 0%, rgb(255, 125, 144) 100%);
    background: -webkit-linear-gradient(173deg, rgb(255, 173, 111) 0%, rgb(255, 125, 144) 100%);
    background: -ms-linear-gradient(173deg, rgb(255, 173, 111) 0%, rgb(255, 125, 144) 100%);
    background: linear-gradient(173deg, rgb(255, 173, 111) 0%, rgb(255, 125, 144) 100%);
}

.wpo-counter-grids .grid:nth-child(3) .counter-number {
    background: -moz-linear-gradient(179deg, rgb(216, 205, 255) 0%, rgb(109, 100, 255) 100%);
    background: -webkit-linear-gradient(179deg, rgb(216, 205, 255) 0%, rgb(109, 100, 255) 100%);
    background: -ms-linear-gradient(179deg, rgb(216, 205, 255) 0%, rgb(109, 100, 255) 100%);
    background: linear-gradient(179deg, rgb(216, 205, 255) 0%, rgb(109, 100, 255) 100%);
}

.wpo-counter-grids .grid p,
.wpo-counter-grids .grid p {
    font-size: 18px;
    font-weight: normal;
    color: #242f6c;
    padding-top: 5px;
    margin-bottom: 0;
    font-weight: 700;
    margin-top: 20px;
    text-align: center;
}

.wpo-counter-grids,
.wpo-counter-grids {
    overflow: hidden;
    position: relative;
}

.odometer.odometer-auto-theme,
.odometer.odometer-theme-default,
.odometer.odometer-auto-theme,
.odometer.odometer-theme-default {
    line-height: 0.8em;
}

.wpo-counter-grids .grid h2 {
    font-size: 25px;
    font-weight: 600;
    margin: 0;
}

.service-area .wpo-counter-grids .grid h2 {
    font-size: 40px;
    color: #00a7d5;
}

@media(max-width: 1200px) {

    .wpo-counter-grids .grid p {
        font-size: 15px;
    }
}

@media(max-width: 992px) {

    .wpo-counter-grids .grid h2,
    .wpo-counter-grids .grid h2 {
        font-size: 25px;
    }


}

@media(max-width: 767px) {

    .wpo-counter-grids .grid,
    .wpo-counter-grids .grid {
        width: 30%;
    }

    .service-title h2 {
        font-size: 26px;
        line-height: 40px;
    }

    .service-area.section-padding {
        padding: 80px 0;
        padding-bottom: 60px;
    }

    .service-area .wpo-counter-grids .grid {
        margin-bottom: 20px;
        width: 25%;
    }
}

@media(max-width: 530px) {

    .wpo-counter-grids .grid,
    .wpo-counter-grids .grid {
        width: 46%;
    }

    .routine-table ul li {
        font-size: 14px;
        padding: 8px;
        flex-basis: 69.33%;
    }


}

@media(max-width: 450px) {

    .wpo-counter-grids .grid {
        width: 100%;
        margin-bottom: 30px;
    }

    .wpo-counter-grids .grid:last-child {
        margin-bottom: 0;
    }

    .service-area .wpo-counter-grids .grid {
        margin-bottom: 20px;
        width: 45%;
    }
}
