.image-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
}

.image-card {
  position: relative;
  width: 100%;
}

.image-container {
  position: relative;
  width: 100%;
  height: 250px; 
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.title {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  font-size: 16px;
}

.description {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  color: black;
  padding: 20px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.image-container:hover .description {
  opacity: 1;
}

/* Tablets or medium devices (max-width: 1024px) - show 2 images per row */
@media (max-width: 1024px) {
  .image-gallery {
    grid-template-columns: repeat(2, 1fr);
  }

  .image-container {
    height: 220px;
  }

  .title {
    font-size: 14px;
  }
}

/* Mobile devices (max-width: 600px) - show 1 image per row */
@media (max-width: 600px) {
  .image-gallery {
    grid-template-columns: 1fr;
  }

  .image-container {
    height: 200px;
  }

  .title {
    font-size: 12px;
  }

  .description {
    padding: 15px;
  }
}

